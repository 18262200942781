.chatbot {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Ensures the chatbot is above other elements */
}

.chat-window {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px; /* Space between the toggle and chat window */
}

.chat-messages {
  flex: 1;
  padding: 10px;
}

.message {
  margin: 5px 0;
}

.message.user {
  text-align: right;
}

.message.bot {
  text-align: left;
}

.chat-input-form {
  display: flex;
}

.chat-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.send-button {
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
